import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { md } from 'utils/breakpoints';
import TitleName from 'components/UI/Header/TitleName';
import IconGirl from 'images/girl.svg';
import { useAPI } from 'utils/api';
import { navigate } from 'gatsby';

const Container = styled.div`
  width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media (max-width: ${md}) {
    margin: 0 38px;
    width: auto;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #3b3516;
  margin: 25px 0 20px;
`;

const Message = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  margin-bottom: 50px;
  line-height: 23px;
`;

const Btn = styled.div`
  width: 230px;
  height: 60px;
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Maintenance = (props) => {
  const [msg, setMsg] = useState('');
  const api = useAPI();

  const checkMaintenance = () => {
    api.getAppInfo().then((info) => {
      const nowDate = Date.parse(new Date());
      const constructionStart = Date.parse(info.underConstructionPeriodStart);
      const constructionEnd = Date.parse(info.underConstructionPeriodEnd);
      const isOnPeriod =
        nowDate > constructionStart && nowDate < constructionEnd;
      if (!isOnPeriod) {
        sessionStorage.removeItem('MaintenanceMsg');
        navigate('/', { replace: false });
      }
    });
  };

  useEffect(() => {
    const msg = sessionStorage.getItem('MaintenanceMsg');
    if (msg) {
      setMsg(msg);
    }

    checkMaintenance();
  }, []);

  return (
    <Container>
      <TitleName />
      <img src={IconGirl} />
      <Title>系統維護</Title>
      <Message>{msg}</Message>
    </Container>
  );
};

export default Maintenance;
